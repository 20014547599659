<template>
	<div>
        <v-container fluid class="grid-list-xl pb-0 mt-12">
            <!-- iob Synthetic Indices -->
            <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.syntheticIndices")}}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-line-chart id="BTCSyntheticIndices" :productArray="['China A50/BTC', 'SPX/BTC']"></synthetic-indices-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-line-chart id="GOLDSyntheticIndices" :productArray="['China A50/GOLD', 'SPX/GOLD']"></synthetic-indices-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-line-chart id="BTCGOLDSyntheticIndices" :productArray="['BTC/GOLD']"></synthetic-indices-line-chart>
                </v-col>
                <!-- 20221216：综合指数下线'DEFI-PERP/ETH' -->
                <!-- <v-col cols="12" sm="4" md="4" class="pl-0">
                    <synthetic-indices-line-chart id="DEFIPERPETHSyntheticIndices" :productArray="['DEFI-PERP/ETH']"></synthetic-indices-line-chart>
                </v-col> -->
            </v-row>
        </v-container>
        <app-footer></app-footer>
        <mobile-btn></mobile-btn>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import SyntheticIndicesLineChart from "@/highcharts/synthetic/SyntheticIndicesLineChart";
    export default {
        data(){
            return {
                
            }
        },
        components: {
            SyntheticIndicesLineChart,
        },
        created(){
            this.$store.dispatch("indexHeaderTitleHandler","syntheticIndices");
        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive",'darkMode'])
        },
        watch:{

        },
        methods: {

        },
    }
</script>